<template>
<div>
  <h2> Settings</h2>
  <router-view
  v-if="loaded"
  />
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Settings',
  computed: {
    ...mapState('user', ['loaded']),
  },
  mounted() {
    this.$store.dispatch('user/getUserInfo');
  },

};
</script>

<style>

</style>
